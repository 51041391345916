<template>
    <div>
        <div class="gallery-container">
            <div class="gallery-row-container" v-for="n in rowCount" :key="n">
                <div class="gallery-row">
                    <div class="gallery-cell-container" v-for="c in imgsForRow(n)" :key="c">
                        <div class="gallery-cell" @click.stop="openImg(n ,c)">
                            <div class="gallery-img-container" :class="{ 'cell-border' : getImgString(n, c) != '' }">
                                <img :src="getImgString(n, c)" class="cell-img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'flex-gallery',
    // emits: ['openImg'],
    data() {
        return {
            cellsPerRow: 12,
            rows: 12,
            // cells: 6,
            windowWidth: 0,
        }
    },
    computed: {
        gallery() {
            const imageData = this.$store.state.gallery;
            return imageData ? this.transformGalleryToStrings(imageData) : [];
        },
        rowCount() {
            // return this.$store.state.gallery.length / this.cellsPerRow;
            const rows = Math.floor(this.testImgCount / this.columnCount);
            const totalImgs = rows * this.columnCount;
            return totalImgs < this.testImgCount ? rows + 1 : rows;
        },
        totalImages() {
            let total = this.rowCount * this.columnCount;
            if (total < this.gallery.length) {
                total = total -( this.rowCount * this.columnCount);
            }
            // const extraImgs = this.gallery.length  - this.totalImages;
            // return extraImgs > 0 ? totalImgs + extraImgs : totalImgs;
            return total;
        },
        testImg() {
            return this.gallery[0];
        },
        testImg2() {
            return this.gallery[1];
        },
        columnCount() {
            const cols = Math.floor(this.windowWidth / 100) - 1;
            return cols > 2 ? cols - 1 : cols;
        },
        testImgCount() {
            return 85;
        }


    },
    methods: {
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
        transformGalleryToStrings(gallery) {
            const imgStrings = [];
            if (gallery) {
                gallery.forEach( imgData => {
                    const imgString = `${imgData}`;
                    imgStrings.push(imgString);
                });
            }
            return imgStrings;

        },
        getRow(rowNum) {
            const row = [];
            if (rowNum <= this.rowCount) {
                const offSet = rowNum * this.cellsPerRow;
                for(let i = offSet; i < offSet + this.cellsPerRow; i++) {
                    row.push(this.gallery[i]);
                }
            }
            return row;
        },
        imgsForRow(rowNum) {
            const endImgCount = rowNum * this.columnCount;
            if (endImgCount > this.gallery.length) {
                return (this.gallery.length - ((rowNum -1 ) * this.columnCount));
            }
            return this.columnCount;
        },
        getImgString(row, column) {
            const index = ((row - 1) * this.columnCount) + column - 1;
            // console.log(index);
            if (index < this.gallery.length) {
                
            return require('../assets/' + this.gallery[index]);
            }
            return "";
        },
        openImg(row, column) {
            const index = ((row - 1) * this.columnCount) + column - 1;
            console.log(index);
            if (index < this.gallery.length) {
                const popupImgName = this.$store.state.gallery[index];
                this.$emit('openImg', popupImgName);

            }
        }
        

    },
    created() {
        this.updateWindowWidth();
    },
    mounted() {
        window.addEventListener('resize', this.updateWindowWidth);
    },
    unmounted() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }

}
</script>

<style scoped>

.gallery-container {
    border: 2px solid black;
    height: 100%;
    /* width: 85%; */
    width: 100%;


    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: darkgray; */


  /* background-color: gray; */
  /* background-image: url("../assets/flagge-portugal.jpg");
  /* background-size: 100%; */
  /* background-size: cover;  */

  /* background-position-y: left; */
}

.gallery-row-container {
    /* border: 2px solid red; */
    height: 120px;
    margin: 3px;
}

.gallery-row {
    height: 120px;
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
    display: flex;
    align-items: center;
    /* border: 2px solid green; */
}


.gallery-cell-container {
    /* border: 2px solid blue; */
    height: 110px;
    margin: 3px;
    /* display: flex; */
}

.gallery-cell {
    height: 110px;
    /* border: 2px solid purple; */
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.gallery-img-container {

    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    /* border: 2px solid yellow; */
}

.cell-img {

    max-width: 100px;
    max-height: 100px;
}

.cell-border {
    border: 3px solid black;
}

</style>