<!-- <script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

</script> -->


<template>
  <div class="popup-container"  :style="{ width: popupWidth, 'background-color': popupBgColor, position: relative }"
    @click.stop="swallow">
    
      <div class="popup-text-container">

        <div class="img-info">
       
            <a href="#" class="btn-prev" @click.prevent.stop="previous" v-show="showPrevious">
                <font-awesome-icon icon="fa-solid fa-angle-left" />
            </a>

        <img :src="require('../assets/' + imageName)" class="img-data" />


        <a href="#" class="btn-next" @click.prevent.stop="next" v-show="showNext">
            <font-awesome-icon icon="fa-solid fa-angle-right" />
        </a>
        
        </div>
      </div>
      
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
    props: ['popupWidth', 'popupHeight', 'popupBgColor', 'imageName', 'showPrevious', 'showNext'],
    data() {
        return {
            
        }
    },
    components: {
        FontAwesomeIcon
    },
    computed: {
        imgPath() {
            // return require(' + this.imageName);
            // return require(this.imageName);
            return '';
        },
        widthStyle() {
            return '{ width: "' + this.popupWidth + '"}';
        },
        widthVal() {
            const val = parseInt(this.popupWidth);
            return val + 'px';
        },
        heightVal() {
            const val = parseInt(this.popupHeight);
            return val + 'px';
        }
    },
    methods: {
        next() {
            this.$emit('next');
        },
        previous() {
            this.$emit('prev');
        },
        swallow(){

        }
    },
    created() {
        // console.log('window-height: ' + window.height)
    }

}
</script>

<style scoped>
.popup-container {
    /* background-color: blue; */
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    border-radius: 10px;
    /* display: inline-block; */
    /* background-color: gray; */
    position: relative;
    z-index: 99;
    max-height: 80%;
}

.popup-text-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bolder;

}

.popupBtns {
    margin-top: 20px;
}

button {
    margin: 5px;
    border-radius: 5px;
    width: 80px;
    background-color: gainsboro;
}

/*
button:hover {
    background-color: ghostwhite;
} */

.not-default {
    border-width: 0;
}

.default, button:active {
    border-width: 2px;
}

.max-info {
    /* display: inline-block;
    max-width: 100%; 
    max-height: 100%; */
    /* width: 100%;
    height: 100%; */
    width: 900px;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}



.img-data {
    position: relative;
    /* max-width: 860px; */
    max-width: 80%;
    max-height: 80%;
    padding: 0 5px;
    /* position: absolute; */
}

a, a:visited {
    color: black;
}


.btn-prev, .btn-next {

    padding: 5px;
    background: gray;
    font-weight: bolder;
    font-size: 30px !important;
}

.btn-prev {
    margin-left: 15px;
}

.btn-next {
    margin-right: 15px
}




.btn-prev:hover, .btn-next:hover {
    background-color: white;
}


.img-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hide {
    display: none;
}


@media (hover: none) {

    .btn-prev:hover, .btn-next:hover {
        background-color: gray;
    }
    
} 

@media  only screen and (max-width: 480px) {

    /* .popup-container {

        display: flex;
        align-content: center;

    } */

    
}

</style>