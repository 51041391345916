import Vue from 'vue'
import App from './App.vue'
import store from './store'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'



Vue.config.productionTip = false


library.add(fas, faAngleLeft, faAngleRight);

new Vue({
  store,
  library,
  FontAwesomeIcon,
  render: h => h(App)
}).$mount('#app')
