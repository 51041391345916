import { render, staticRenderFns } from "./ImagePopUp.vue?vue&type=template&id=27c8babf&scoped=true"
import script from "./ImagePopUp.vue?vue&type=script&lang=js"
export * from "./ImagePopUp.vue?vue&type=script&lang=js"
import style0 from "./ImagePopUp.vue?vue&type=style&index=0&id=27c8babf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27c8babf",
  null
  
)

export default component.exports