import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gallery: [
      'Porto Apartment - 1 of 85.jpeg',
      'Porto Apartment - 2 of 85.jpeg',
      'Porto Apartment - 3 of 85.jpeg',
      'Porto Apartment - 4 of 85.jpeg',
      'Porto Apartment - 5 of 85.jpeg',
      'Porto Apartment - 6 of 85.jpeg',
      'Porto Apartment - 7 of 85.jpeg',
      'Porto Apartment - 8 of 85.jpeg',
      'Porto Apartment - 9 of 85.jpeg',
      'Porto Apartment - 10 of 85.jpeg',
      'Porto Apartment - 11 of 85.jpeg',
      'Porto Apartment - 12 of 85.jpeg',
      'Porto Apartment - 13 of 85.jpeg',
      'Porto Apartment - 14 of 85.jpeg',
      'Porto Apartment - 15 of 85.jpeg',
      'Porto Apartment - 16 of 85.jpeg',
      'Porto Apartment - 17 of 85.jpeg',
      'Porto Apartment - 18 of 85.jpeg',
      'Porto Apartment - 19 of 85.jpeg',
      'Porto Apartment - 20 of 85.jpeg',
      'Porto Apartment - 21 of 85.jpeg',
      'Porto Apartment - 22 of 85.jpeg',
      'Porto Apartment - 23 of 85.jpeg',
      'Porto Apartment - 24 of 85.jpeg',
      'Porto Apartment - 25 of 85.jpeg',
      'Porto Apartment - 26 of 85.jpeg',
      'Porto Apartment - 27 of 85.jpeg',
      'Porto Apartment - 28 of 85.jpeg',
      'Porto Apartment - 29 of 85.jpeg',
      'Porto Apartment - 30 of 85.jpeg',
      'Porto Apartment - 31 of 85.jpeg',
      'Porto Apartment - 32 of 85.jpeg',
      'Porto Apartment - 33 of 85.jpeg',
      'Porto Apartment - 34 of 85.jpeg',
      'Porto Apartment - 35 of 85.jpeg',
      'Porto Apartment - 36 of 85.jpeg',
      'Porto Apartment - 37 of 85.jpeg',
      'Porto Apartment - 38 of 85.jpeg',
      'Porto Apartment - 39 of 85.jpeg',
      'Porto Apartment - 40 of 85.jpeg',
      'Porto Apartment - 41 of 85.jpeg',
      'Porto Apartment - 42 of 85.jpeg',
      'Porto Apartment - 43 of 85.jpeg',
      'Porto Apartment - 44 of 85.jpeg',
      'Porto Apartment - 45 of 85.jpeg',
      'Porto Apartment - 46 of 85.jpeg',
      'Porto Apartment - 47 of 85.jpeg',
      'Porto Apartment - 48 of 85.jpeg',
      'Porto Apartment - 49 of 85.jpeg',
      'Porto Apartment - 50 of 85.jpeg',
      'Porto Apartment - 51 of 85.jpeg',
      'Porto Apartment - 52 of 85.jpeg',
      'Porto Apartment - 53 of 85.jpeg',
      'Porto Apartment - 54 of 85.jpeg',
      'Porto Apartment - 55 of 85.jpeg',
      'Porto Apartment - 56 of 85.jpeg',
      'Porto Apartment - 57 of 85.jpeg',
      'Porto Apartment - 58 of 85.jpeg',
      'Porto Apartment - 59 of 85.jpeg',
      'Porto Apartment - 60 of 85.jpeg',
      'Porto Apartment - 61 of 85.jpeg',
      'Porto Apartment - 62 of 85.jpeg',
      'Porto Apartment - 63 of 85.jpeg',
      'Porto Apartment - 64 of 85.jpeg',
      'Porto Apartment - 65 of 85.jpeg',
      'Porto Apartment - 66 of 85.jpeg',
      'Porto Apartment - 67 of 85.jpeg',
      'Porto Apartment - 68 of 85.jpeg',
      'Porto Apartment - 69 of 85.jpeg',
      'Porto Apartment - 70 of 85.jpeg',
      'Porto Apartment - 71 of 85.jpeg',
      'Porto Apartment - 72 of 85.jpeg',
      'Porto Apartment - 73 of 85.jpeg',
      'Porto Apartment - 74 of 85.jpeg',
      'Porto Apartment - 75 of 85.jpeg',
      'Porto Apartment - 76 of 85.jpeg',
      'Porto Apartment - 77 of 85.jpeg',
      'Porto Apartment - 78 of 85.jpeg',
      'Porto Apartment - 79 of 85.jpeg',
      'Porto Apartment - 80 of 85.jpeg',
      'Porto Apartment - 81 of 85.jpeg',
      'Porto Apartment - 82 of 85.jpeg',
      'Porto Apartment - 83 of 85.jpeg',
      'Porto Apartment - 84 of 85.jpeg',
      'Porto Apartment - 85 of 85.jpeg'
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
