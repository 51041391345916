<template>
  <div>
      <div class="popup-dialog">
      <image-pop-up
        ref="popupEl"
        popupWidth="900px"
        popupHeight="popupHeightVal"
        popupBgColor="rgb(169, 169, 169)"
        popupText="Are you sure?"
        type="confirm"
        okText="OK"
        cancelText="Cancel"
        @ok="okClicked"
        @cancel="cancelClicked"
        @click.stop="handleClick"
        @next="rightClicked"
        @prev="leftClicked"
        v-show="popupOpen"
        class="popup"
        :imageName="popupImgName"
        :showNext="showNext"
        :showPrevious="showPrevious"
      />
    </div>
  </div>
</template>

<script>
import ImagePopUp from './ImagePopUp.vue'
export default {
  // emits: ['next', 'previous'],
  props: ['showPrevious', 'showNext'],
  components: {
    ImagePopUp
  },
  data() {
    return {
      popupOpen: false,
      popupImgName: ''
    };
  },
  computed: {
    gallery() {
      return this.$store.state.gallery;
    },
    popupHeightVal() {
      const size = window.innerWidth;
      return size > 640 ? this.popupHeight : this.popupHeight / 2;
    },
    // bgColorVal() {
    //   // return this.popupHeightVal < this.popupHeight ? 'rgb(169, 169, 169)' : 'rgba(169, 169, 169, .8)';
    // }
  },
  methods: {
    okClicked() {
      this.hidePopUp();
    },
    cancelClicked() {
      this.hidePopUp();
    },
    enterPressed() {
      if (this.popupOpen) {
        // this.$refs.popupEl.defaultClicked();
        this.hidePopUp();
      }
    },
    leftClicked() {
      this.$emit('previous',  this.popupImgName);
    },
    rightClicked() {
      this.$emit('next', this.popupImgName);
    },
    tabPressed() {
      if (this.popupOpen) {
        this.$refs.popupEl.tabPressed();
      }
    },
    hidePopUp() {
      this.popupOpen = false;
    },
    showPopUp() {
      this.popupOpen = true;
    },
    openImg(name) {
      // console.log(window.width)
                this.popupImgName = name;

            this.showPopUp();
     },
     handleClick() {

     }
          
  },
  mounted() {
    window.addEventListener("keydown", (e) => {
      e.stopPropagation();
      if (e.key == "Enter" || e.key == "Escape" ) {
        this.enterPressed();
      } else if (e.key == 'ArrowRight') {
        this.rightClicked();
      }else if (e.key == 'ArrowLeft') {
        this.leftClicked();
      }
    });
  },
};
</script>

<style scoped>

.popup-dialog {
  /* position: absolute; */
  /* top: 50px; */
  /* top: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  z-index: 20;
}

.popup {
  /* top: 50px;
  left: 35%;
  position: fixed; */
}



@media only screen and (min-width: 480px) and (max-width: 767px) {


  .popup-dialog {

  /* align-items: flex-start; */
  }
}

</style>