<template>
  <div>
  <div @click="closePopup" id="app-container">


    <div id="popup-container">
      <PopUpDemo ref="imgPopUp" id="popup-window"
        :showNext="popupShowNext"
        :showPrevious="popupShowPrev"
        @next="handleNext" 
        @previous="handlePrevious" />
    </div>
    
     <header>

      <div id="header-grid-section">

      <div class="info-container">
        <div class="info-txt">
              
              
              <div id="header-top" class="header-heading">
                <div class="tagline">Our First Aparmtent in Porto...</div>
                <div class="header-heading-inner">
                  <div class="header-heading-col1">
                    <div>
                      Location: District Campo 24 de Agosto, Bonfim, Porto
                    </div>
                    <div class="sub-heading">
                      <!-- With fully equipped and furnished kitchen and renovated. -->
                      Rua Dom Agostinho de Jesus e Sousa
                    </div>
                  </div>

                  <div class="header-heading-col2">

                    <div class="white">
                      Apartment T5 - 700m from Campo 24 de Agosto - Porto
                    </div>
                    <div class="sub-heading white">
                      Fully equipped and furnished kitchen and renovated
                    </div>
                  </div>  
                </div>
              </div>  

              <div id="header-main">
                <ul>
                  <li>Easy access to bus and metro.</li>
                  <li>Lots of light in all environments.</li> 
                  <li>Features:</li>
                  <div class="bullet-cols">
                      <div id="bullets-col-1">
                        <ul>
                          <li>Entrance hall</li>
                          <li>Fully equipped kitchen</li>
                          <li>Laundry</li>
                          <li>Large living room with balcony</li>
                          <li>Complete bathroom</li>
                        </ul>
                      </div>
                      <div id="bullets-col-2">
                        <ul>
                          <li>3 bedrooms, with built-in wardrobes, 2 with balcony</li>
                          <li>3 bathrooms (2 en suite)</li>
                          <li>Very spacious and bright</li>
                          <li>Garage space</li>
                          <li>Doorman and video surveillance</li>
                        </ul>
                      </div>
                      <div id="bullets-col-3">
                          <ul>
                            <li>Parking space included in the price</li>
                            <li>Storeroom</li>
                            <li>Unfurnished</li>
                            <li>With Lift</li>
                          </ul>
                      </div>
                  </div>

                  
                  
                </ul>
              </div>

              
            </div>
            <!-- <p>

              In addition to an excellent property to live in, it is also in a great location that allows you to have several points of commerce, services and public transport around you,
              namely bus and metro.</p>
              
              <p>Usage license n°182 issued on by the Porto City Council</p>
              <p>Energy Classification SCE: D.</p>

              <p>
              Basic features
            </p>
            <ul>
              <li> 170m<sup>2</sup></li>
              <li>T5</li>
              <li>3 bathrooms</li>
              <li>Terrace</li>
              <li>Parking space included in the price</li>
              <li>Second hand/good condition</li>
              <li>Storeroom</li>
              <li>Fully equipped kitchen and unfurnished house</li>

              <p>
              Building
            </p>
            <ul>
              <li>With lift</li>
            </ul>

            <P>
              Energy performance certificate
            </P>
            <ul>
              <li>Energy efficiency rating: D</li>
            </ul>
            </ul>-->
            <!-- </div> -->
        </div>
      </div>

      <!--

      <div id="header-info">
      
      <div id="bldg-section">

        <div id="bldg-img-wrap">
            <img src="./assets/Porto Apartment - 1 of 85.jpeg" class="bldg-img" />
        </div>

      </div>

      <div id="listing-section">

        <div id="listingimg-wrap">
          <img src="./assets/listing-info.jpg" class="listing-img" />
        </div>

      </div>-->

      <!-- </div> -->
    </header>


    <main>

   

    <FlexGalleryLocal @openImg="handleOpenImage" class="gallery-section"/>
    
  </main>
  </div>
  </div>
</template>

<script>

import FlexGalleryLocal from './components/FlexGalleryLocal.vue';
import PopUpDemo from './components/PopUpDemo.vue';
export default {
  components: {
    FlexGalleryLocal,
    PopUpDemo
},
data() {
  return {
    documentUrl: '/listing.pdf',
    curPopupIndex: 0

  }
},
computed: {
  gallery() {
    return this.$store.state.gallery;
  },
  popupShowNext() {
    return this.curPopupIndex <this.gallery.length - 1;
  },
  popupShowPrev() {
    return this.curPopupIndex > 0
  }


},
  methods: {
    handleOpenImage(name) {
      console.log(window.innerWidth);
      const index = this.$store.state.gallery.indexOf(name);
      if (index >= 0) {
        this.curPopupIndex = index;
        this.$refs.imgPopUp.openImg(name);
      }
    },
    handleNext(curName) {
      const index = this.$store.state.gallery.indexOf(curName);
      if (index >= 0 && index < this.$store.state.gallery.length - 1) {
        this.curPopupIndex = index + 1;
        this.$refs.imgPopUp.openImg(this.$store.state.gallery[index + 1]);
      }
    },
    handlePrevious(curName) {
      const index = this.$store.state.gallery.indexOf(curName);
      if (index > 0 && index < this.$store.state.gallery.length) {
        this.curPopupIndex = index - 1;
        this.$refs.imgPopUp.openImg(this.$store.state.gallery[index - 1]);
      }
    },
    closePopup() {
      this.$refs.imgPopUp.hidePopUp();
    },
    phoneMode() {
        const size = window.innerWidth;
        return size <= 480;
    }
  }
}
</script>


<style >

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap');

html {


  /* background-image: url("./assets/flagge-portugal.jpg") !important;  */


  background-image: url("./assets/Porto Apartment - 2 of 85.jpeg") !important; 
  background-attachment: fixed; 
  background-repeat: no-repeat;
  /* background-size: 100%; */
  background-size: cover; 

  /* background-position-y: left; */
  
}

/* #app-container */

#app-container, header, body, .gallery-section {
  position: relative;
}

header, .gallery-section {
  z-index: 2;
}

#app-container, body {
  background-color: rgba(169, 169, 169, .4) !important;
}

body {
  margin:  0  8px !important;
}


/* #app-container, header, main {
  position: relative;
} */

header {


background-image: url("/src/assets/Porto Apartment - 2 of 85.jpeg") !important;
  background-size: 100%;
  background-size: cover; 

}

</style>

<style scoped>


.info-container {
  width: 100%;
  height: 300px;
  padding-left: 10px;
}

.info-txt {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 300px;
  overflow-y: hidden;
  font-family: 'Vollkorn', serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-size: 18px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
    /* "info-col-1 info-col-2"; */
    "header-top header-top"
    "header-main header-main";
}

#header-grid-section, .info-container, .info-txt {
  height: 100%;
  position: relative;
  z-index: 2;
}

.tagline {

  color: white;
  padding: 10px;
  font-size: 20px;
}

.header-heading {
  margin-top: 10px;
  background-color:  rgba(218, 41, 28, .7);
  padding: 10px;
  color: #046A38;
  /* color: white; */
  font-weight: bolder;
  width: 100%;
}

.header-heading-inner {
  width: 95%;
  background-color:  #046A38;
  margin: 10px;
  padding: 5px;
  color: #FFE900;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
    "hdr-hdng-col-1 hdr-hdng-col-2";
}

.header-heading-col1 {
  grid-area: hdr-hdng-col-1;
}


.header-heading-col2 {
  grid-area: hdr-hdng-col-2;
}


.sub-heading {
  /* color: white; */
  /* margin-top: 5px; */
  font-size: 12px;
  margin-left: 10px;
}

.white {
  color: white;
}

#header-top {
  grid-area: header-top;
}

#header-main {
  width: 100%;
  grid-area: header-main;
  overflow-y: auto;
  z-index: 2;
}
.header-text-inner {
  /* background-color: rgba(218, 41, 28, .3); */

  margin: 10px;

  padding: 5px;
}

.info-col-1 {
  grid-area: info-col-1;
}


.info-col-2 {
  grid-area: info-col-2;
}

.bullet-cols, .bullet-cols-phone {
  width: 100%;
  font-size: 16px;
}


.bullet-cols {

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 
    "list-col-1 list-col-2 list-col-3";

}

#bullets-col-1 {
  grid-area: list-col-1;
}


#bullets-col-2 {
  grid-area: list-col-2;

}


#bullets-col-3 {
  grid-area: list-col-3;

}

#bullets-col-2 ul {
  padding-left: 0;
}


header {
  /* position: fixed; */
  top: 0px;
  height: 400px;
  width: 100%;
  /* Porto Apartment - 2 of 85 */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center;
  align-items: center; */

}

#header-info {
  width: 100%;
  background-color: gray;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
    "bldg listing";
  justify-items: end;

}

#bldg-section {
  grid-area: bldg;
  width: 50%;
  height: 100%;

}


#bldg-img-wrap {
  /* max-width: 600px */
  /* height: 300px; */
  width: 100%;
}

.bldg-img {
  width: auto;
  height: 300px;
}



#listing-section {
  grid-area: listing;
  width: 50%;
  height: 100%;
  justify-self: start;
}


#listing-img-wrap {
  /* max-width: 600px */
  height: 300px;
  width: 100%;
}

.listing-img {
  width: auto;
  height: 300px;
}


#app {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  

}


#app-container {
  width: 100%;
  height: 100%;


  

}

.header-grid {
  /* display: grid;
  grid-template-columns: 280px 1fr;
  grid-template-areas: 
    "hdr-img hdr-desc";
    align-items: center; */
    width: 100%;
    background-image: url("/src/assets/flagge-portugal.jpg"); 
  background-size: 100%;
  background-size: cover; 
  display: flex;
  justify-content: space-around;

  /* background-position-y: center; */

  /* background-color: gray; */


  /* background-color: gray; */
  
}

#hdr-img {
  grid-area: hdr-img;
  /* max-width: 300px; */
  flex-grow: 1;
}

#hdr-desc-wrap {
  position: relative;
  height: 100%;
}


#hdr-desc {
  grid-area: hdr-desc;
  height: 300px;
  width: 98%;
  font-size: 14px;
  border: 2px solid black;
  /* padding: 5px; */
  margin-right: 5px;
  overflow-y: auto;
  flex-grow: 3;
  /* background-color: gray; */
  /* background-image: url("/src/assets/flagge-portugal.jpg"); */
  /* background-size: 100%; */
  /* background-size: cover;  */

  /* background-position-y: center; */
  /* background-clip: padding-box; */
  /* color: black; */
  /* z-index: 3; */
  /* position: relative; */
  
}

#desc-txt {
  font-weight: bolder !important;
  height: 84%;

  overflow-y: auto;

  border: 2px black solid;
  padding: 4px;
  width: 100%;
}

#desc-txt p {
  font-weight: bolder;
  margin-bottom: 2px;
}

#hdr-desc-txt-wrap {
  position: relative;
  z-index: 9;
  background-color: rgba(169, 169, 169, .7);

  padding: 5px;
  margin: 0 2px;

  border: 2px black solid;
  height: 100%;
  width: 100%;
  

}


#hdr-desc-txt {
  color: yellow;
  font-size: 24px;
  font-weight: bolder;
  padding-left: 10px;
  background-color: #0D6938;
  height: 30px;
}
/* 
#hdr-desc-text-wrap:before {
  opacity: .6;
} */

.pdf-section {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  margin-right: 10%;
}


.pdf-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 95%;
  border: 4px black solid;
  padding: 2px;
  /* margin-top: 30px; */
  margin-left: 10px;
  margin-right: 10%;
  height: 300px;

  background-color: #0D6938;
}

.pdf-data {
  max-width: 100%;
  height: 300px;
  top: 0;
  /* display: fixed; */
  /* position: absolute; */
  margin-top: 0;
  flex-grow: 1;
  /* flex-basis: 60%; */
}

.img-data {
  height: 300px;
  width: auto;
}

.info-img {
  width: 50%;
  height: auto;
}

.listing-info-wrap {
  width: 100%;
  height: 300px;
  text-align: center;

}

/* 
header {
  position: fixed;
  top: 0px;
  height: 330px;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center;
  align-items: center;

}  */

.listing {
  width: 100%;
  height: 300px;
}

main {
  /* margin-top: 330px; */
  width: 100%;

  /* background-color: rgba(169, 169, 169, .8) !important; */
}

.gallery-section {
  width: 95%;
  margin: 0 10px;
  /* background-color: gray; */
}

.header-img {
  height: 300px;
  width: auto;
}
/* 
.gallery-section {
  position: relative;
  z-index: 5;
}*/

#popup-window {
  position: fixed;
  z-index: 20;
  margin-top: 30vh;
}

#pop-upcontainer {
  -webkit-transform: translate3d(0, 0, 0);
  /* transform: translate3d(0, 0, 200px); */

  position: absolute;
  z-index: 99;

  height: 100vh;
  display: flex;
  align-items: center;
  /* top:  */
}

/* #popup-container {
  position: absolute;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */



@media only screen and (max-width: 480px)  {


  /* #header-main {
    overflow-y: visible;
  }
  .info-text {
    height: auto;
  } */

  header, #header-grid-section, .info-container, .info-text,   #header-main  {
   height: fit-content !important
  }

  #header-main {
    overflow: visible;
  }
/* 

    .bullet-cols {


      grid-template-rows: 1fr 1fr 1fr;

      grid-template-areas: 
        "list-col-1"
        "list-col-2"
        "list-col-3"

    } */
  
}

@media only screen and (min-width: 480px) and (max-width: 767px) {

  header, #header-grid-section, .info-container, .info-text,   #header-main  {
   height: fit-content !important;
  }

  #header-main {
    overflow: visible;
  }

  #pop-upcontainer {
    position: fixed;
    top: 0px;
  }
    
}
</style>
